@media (min-width: 900px) {
  .show-mobile {
    display: none;
  }
}

@media (max-width: 900px) {
  .dont-show-mobile {
    display: none;
  }
}
