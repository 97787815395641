.hidden {
	display: none;
}
#root {
	display: flex;
}
.debug-table {
	width: 100%;
}
.debug-table td,
.debug-table th {
	border: 1px solid red;
	padding: 4px;
}
