.gm-style .gm-style-iw-d::-webkit-scrollbar-track,
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-t::after {
    background: #254467;
    color: #fff;
}
.gm-style .gm-style-iw-tc::after {
    background: #254467;
}
.gm-ui-hover-effect > span {
    background-color: #fff;
}
.gm-style .gm-style-iw-c {
    padding: 5px;
}
.gm-style .gm-style-iw-d::-webkit-scrollbar {
    height: 4px;
}
